import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';

interface Props {
  label: any;
  disabled?: boolean;
  value?: any;
  onChange?: (e: any) => void;
}

export const MyCheckBoxTask: FunctionComponent<Props> = ({
  label,
  disabled,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      control={
        <Checkbox checked={value} onChange={onChange} disabled={disabled} />
      }
      label={label}
    />
  );
};

MyCheckBoxTask.defaultProps = {
  value: '',
  disabled: false,
  onChange: undefined,
};
