import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiGetReestr = async (state: string, search?: string) => {
  const params: IApiParams = {
    url:
      search === undefined
        ? `/reestr/getReestr.php?state=${state}`
        : `/reestr/getReestr.php?state=${state}&search=${search}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'GET');
};
