import React from 'react';
import {
  AppBar,
  Box,
  createTheme,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#009688',
    },
  },
});

export const SignNavigationBar = () => {
  const { i18n } = useTranslation();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Toolbar>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={0}>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'revert',
                    fontWeight: 750,
                    fontSize: '28px',
                    color: '#FFFFFF',
                  }}
                  component="text"
                >
                  ISOft
                </Typography>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
};
