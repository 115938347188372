export const MAIN_COLOR_STRING = '#26a69a';
export const MOBILE_VIEW = 0;
export const PAD_VIEW = 1;
export const PC_VIEW = 2;
export const DEVICE_STATUS = 'device';
export const STATE = 'state';
export const ACCESS_TOKEN_NAME = 'access';
export const TOKEN_NAME = 'token';
export const IP_LOCAL_NAME = 'iplocal';
export const REFRESH_TOKEN_NAME = 'refresh';
export const HOLDER_NAME = 'holder';
export const FULLNAME_NAME = 'name';
export const ROOT_NAME = 'root';
export const HASH_NAME = 'hash';
export const JSON_CONTENT_TYPE = 'application/json';
export const FORM_DATA_CONTENT_TYPE = 'form-data';
export const WARNING_DATA_CONTENT_TYPE = 'warning-data';
export const API_REQUEST_TIMEOUT_MILLISECONDS = 300_000;
export const API_REQUEST_TIMEOUT_MILLISECONDS_ERROR_STATUS = -10;
export const VERSION = '1.0';
export const LANGUAGE_RUS = 'rus';
export const LANGUAGE_UZ = 'uz';
export const ACTION_MAGNET_FLOW = 0;
export const ACTION_TEMP = 1;
export const START_PAGE_SIZE_DEVICES = 20;
export const PAGE_SIZE_DEVICES = [20, 50, 100];
export const START_PAGE_SIZE_ARCHIVES = 15;
export const PAGE_SIZE_ARCHIVES = [15, 50, 100];
export const START_PAGE_SIZE_SETUP = 15;
export const PAGE_SIZE_SETUP = [15, 50, 100];

export const API_STATUS_PROBLEM =
  'Произошла ошибка, это все что мы знаем... Попробуйте повторить запрос позже';

export const LEVEL_READ = 1;
export const LEVEL_READ_WRITE = 2;
export const LEVEL_ADMIN = 5;

export const VIEW_ARCHIVE = '0';
export const PDF_ARCHIVE = '1';
export const EXCEL_ARCHIVE = '2';

export const NEO_REG_NUM = '*10043-85*';

export const ROUND_VOLUME = 1000;
export const ROUND_VOLUME_ALL = 1000;
export const ROUND_TEMP = 100;
export const ROUND_PRES = 1000;
export const ROUND_KOMP = 10000;
export const ROUND_TIME_HOUR = 100;
export const ROUND_TEMP_PROC = 100;
export const ROUND_VOLT_PROC = 100;
export const ROUND_BATTERY = 1;
