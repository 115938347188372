import { Card, Divider, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEVICE_STATUS, STATE } from '../../constant/constants';
import { DEVICES_ROUTE } from '../../constant/routes';
import { getDeviceStageDescription, getMainGridSize } from '../../utils/utils';

interface Props {
  param: number;
  color: string;
  value?: number;
  image?: any;
}

export const MainDivGridItem: FunctionComponent<Props> = ({
  param,
  color,
  value,
  image,
}) => {
  const navigate = useNavigate();
  const handleClick = (num: number) => {
    localStorage.setItem(DEVICE_STATUS, getDeviceStageDescription(num));
    localStorage.setItem(STATE, `${num}`);
    navigate(DEVICES_ROUTE);
  };
  return (
    <Grid item xs={getMainGridSize()}>
      <Card
        style={{
          margin: '5px',
          background: '#ffffff',
          cursor: 'pointer',
        }}
        onClick={() => handleClick(param)}
      >
        <Stack padding="20px" justifyContent="space-between" direction="row">
          <Stack
            direction="column"
            width="100%"
            alignItems="flex-start"
            spacing={2}
          >
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '20px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {getDeviceStageDescription(param)}
            </Typography>
            <Divider style={{ width: '100%' }} />
            <Stack
              direction="row"
              width="100%"
              height="100px"
              alignItems="center"
              justifyContent="space-between"
            >
              {value !== undefined && (
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '60px',
                    color: '#000000',
                    letterSpacing: 1,
                  }}
                  component="text"
                >
                  {value}
                </Typography>
              )}
              <SvgIcon
                sx={{
                  fontSize: 60,
                  color,
                }}
                component={image}
              />
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
};

MainDivGridItem.defaultProps = {
  value: undefined,
  image: undefined,
};
