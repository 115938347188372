import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import SaveIcon from '@mui/icons-material/Save';
import {
  getDefaultAlertProps,
  getRootLevel,
  replaceQuoat,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  API_STATUS_PROBLEM,
  LEVEL_READ,
  MAIN_COLOR_STRING,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { MyTextFiled } from '../UI/MyTextField';
import { DeviceInfoDto } from '../../dto/DeviceInfoDto';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiGetInfo } from '../../service/info/apiGetInfo';
import { apiUpdateInfo } from '../../service/info/apiUpdateInfo';
import { DeviceInfoAzsItem } from './DeviceInfoAzsItem';
import { DeviceInfoColoncaItem } from './DeviceInfoColoncaItem';

export const DeviceInfoDiv = () => {
  const { id } = useParams();
  const root = getRootLevel();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [checkHelper] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deviceInfoDto, setDeviceDto] = useState<DeviceInfoDto>();

  const getInfo = async (idDevice: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetInfo(idDevice);
    if (apiResult.isSuccess) {
      setDeviceDto(apiResult.data.info);
      document.title = `${t('headerAzsId')} ${apiResult.data.info.azsId}`;
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const updateInfo = async () => {
    const apiResult: IApiResult = await apiUpdateInfo(deviceInfoDto);
    if (apiResult.isSuccess) {
      setIsEdit(false);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    getInfo(id ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv
            title={t('info')}
            isFlowmeter
            deviceInfo={
              deviceInfoDto &&
              `${t('headerAzsId')} ${deviceInfoDto?.azsId} ${replaceQuoat(
                deviceInfoDto?.holder,
                0
              )}`
            }
          />
        </Stack>
        <Stack direction="column" width="90%" spacing={2}>
          <Card
            style={{
              padding: '20px',
              background: '#f5f5f5',
            }}
          >
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Stack direction="row">
                  {root !== LEVEL_READ && (
                    <IconButton
                      title={t('tooltipEdit')}
                      size="medium"
                      onClick={() => setIsEdit(!isEdit)}
                    >
                      {!isEdit && (
                        <EditIcon fontSize="medium" sx={{ color: '#f44336' }} />
                      )}
                      {isEdit && (
                        <EditOffIcon
                          fontSize="medium"
                          sx={{ color: '#f44336' }}
                        />
                      )}
                    </IconButton>
                  )}
                  <IconButton
                    disabled={!isEdit}
                    title={t('tooltipSave')}
                    size="medium"
                    onClick={updateInfo}
                  >
                    <SaveIcon
                      fontSize="medium"
                      sx={{ color: isEdit ? MAIN_COLOR_STRING : 'gray' }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
              <Divider />
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <MyTextFiled
                    value={replaceQuoat(deviceInfoDto?.name, 0)}
                    onChange={(e) =>
                      setDeviceDto(
                        deviceInfoDto && {
                          ...deviceInfoDto,
                          name: replaceQuoat(e.target.value, 1),
                        }
                      )
                    }
                    error={checkHelper}
                    disabled={!isEdit}
                    type="text"
                    label={t('headerName')}
                    size="small"
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextFiled
                    value={deviceInfoDto?.phone}
                    onChange={(e) =>
                      setDeviceDto(
                        deviceInfoDto && {
                          ...deviceInfoDto,
                          phone: e.target.value,
                        }
                      )
                    }
                    error={checkHelper}
                    disabled={!isEdit}
                    type="text"
                    label={t('headerPhone')}
                    size="small"
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextFiled
                    value={deviceInfoDto?.email}
                    onChange={(e) =>
                      setDeviceDto(
                        deviceInfoDto && {
                          ...deviceInfoDto,
                          email: e.target.value,
                        }
                      )
                    }
                    disabled={!isEdit}
                    error={checkHelper}
                    type="text"
                    label={t('headerEmail')}
                    size="small"
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextFiled
                    value={replaceQuoat(deviceInfoDto?.holder, 0)}
                    onChange={(e) =>
                      setDeviceDto(
                        deviceInfoDto && {
                          ...deviceInfoDto,
                          holder: replaceQuoat(e.target.value, 1),
                        }
                      )
                    }
                    error={checkHelper}
                    disabled={!isEdit}
                    type="text"
                    label={t('headerHolder')}
                    size="small"
                  />
                </Grid>
                <Grid item xs={8}>
                  <MyTextFiled
                    value={replaceQuoat(deviceInfoDto?.location, 0)}
                    onChange={(e) =>
                      setDeviceDto(
                        deviceInfoDto && {
                          ...deviceInfoDto,
                          location: replaceQuoat(e.target.value, 1),
                        }
                      )
                    }
                    disabled={!isEdit}
                    error={checkHelper}
                    type="text"
                    label={t('headerLocation')}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Stack>
          </Card>
          <Card
            style={{
              padding: '20px',
              background: '#f5f5f5',
            }}
          >
            <Stack spacing={1}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#ff5722',
                  letterSpacing: 1,
                }}
                component="text"
              >
                {t('infoAllAzs')}
              </Typography>
              <Divider />
              <Stack direction="row" spacing={1}>
                <DeviceInfoAzsItem
                  title={t('headerAzsVolume')}
                  value={deviceInfoDto?.azsVolume ?? ''}
                />
                <DeviceInfoAzsItem
                  title={t('headerAzsMassa')}
                  value={deviceInfoDto?.azsMassa ?? ''}
                />
                <DeviceInfoAzsItem
                  title={t('headerAzsSum')}
                  value={deviceInfoDto?.azsSum ?? ''}
                />
                <DeviceInfoAzsItem
                  title={t('headerFirst')}
                  value={unixToLocal(deviceInfoDto?.dateFirst) ?? ''}
                />
                <DeviceInfoAzsItem
                  title={t('headerLastConnect')}
                  value={unixToLocal(deviceInfoDto?.dateConnect) ?? ''}
                />
                <DeviceInfoAzsItem
                  title={t('headerConnectNext')}
                  value={unixToLocal(deviceInfoDto?.dateNext) ?? ''}
                />
              </Stack>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  marginTop: '20px',
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#ff5722',
                  letterSpacing: 1,
                }}
                component="text"
              >
                {t('infoColonca')}
              </Typography>
              <Divider />
              <Grid container spacing={1}>
                {deviceInfoDto?.columnArr.map((item) => (
                  <DeviceInfoColoncaItem dto={item} />
                ))}
              </Grid>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </div>
  );
};
