import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

interface Props {
  label: any;
  autoComplete?: string;
  disabled?: boolean;
  multiLine?: boolean;
  width?: string;
  size?: any;
  value?: any;
  type?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (e: any) => void;
}

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'grey',
  },
});

export const MyTextFiled: FunctionComponent<Props> = ({
  label,
  autoComplete,
  multiLine,
  disabled,
  width,
  size,
  value,
  type,
  error,
  helperText,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <CssTextField
      label={label}
      value={value}
      autoComplete={autoComplete}
      variant="filled"
      type={type}
      onChange={onChange}
      error={(error && value === '') || helperText !== ''}
      multiline={multiLine}
      disabled={disabled}
      style={{
        width,
        fontFamily: 'sans-serif',
      }}
      helperText={error && value === '' ? 'Введите значение' : helperText}
      size={size}
    />
  );
};

MyTextFiled.defaultProps = {
  value: '',
  autoComplete: 'off',
  disabled: false,
  multiLine: false,
  width: '100%',
  size: 'large',
  type: 'text',
  error: false,
  helperText: '',
  onChange: undefined,
};
